/** @jsx jsx */
import React, { useState, useMemo } from "react";
import { graphql } from "gatsby";
import { jsx, Container, Box } from 'theme-ui';
import Layout from '../components/Layout';
import CardWrapper from "../components/shared/CardWrapper";
import Pagination from "../components/shared/pagination/Pagination";
import HeroSection from "../components/sections/heroSection";
import SEO from "../components/seo";

export const pageQuery = graphql`
query caseStudiesIndex {
    allContentfulPage(filter: {slug: {eq: "case-studies"}}) {
      nodes {
        id
        seoMetadata {
          description
          keywords
          ogImage {
            gatsbyImageData
            title
          }
          title
        }
        sections {
          ... on ContentfulHeroSection {
           ...ContentfulHeroSectionFragment
          }
        }
        bannerData {
          bannerImage {
            gatsbyImageData(width:30)
          }
          bannerSlug
          bannerText {
            raw
          }
          label
          link
        }
      }
    }
    allContentfulCaseStudy(
        sort: {fields: date, order: DESC}
        ) {
        edges {
          node {
            id
            slug
            description
            title
            author
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              gatsbyImageData
            }
            featuredImageAltText
          }
        }
        totalCount
      }
    file(relativePath: {eq: "background-pattern.png"}) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 2000,
        )
      }
    }
  }
`;

const CaseStudiesIndex = ({ data }) => {
  const contentfulCaseStudies = data.allContentfulCaseStudy.edges;
  const { sections, seoMetadata, bannerData } = data.allContentfulPage.nodes && data.allContentfulPage.nodes.length > 0 ? data.allContentfulPage.nodes[0] : [];
  const allPosts = [...contentfulCaseStudies].sort((a, b) => {
    const aDate = a.node.date || a.node?.frontmatter?.date;
    const bDate = b.node.date || b.node?.frontmatter?.date;
    return (
      new Date(bDate) - new Date(aDate)
    );
  });

  const pageNumberLimit = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onPrevClick = () => {
    if ((currentPage - 1) <= minPageLimit) {
      setMaxPageLimit(maxPageLimit - (pageNumberLimit - 1));
      setMinPageLimit(minPageLimit - (pageNumberLimit - 1));
    }

    setCurrentPage(prev => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + (pageNumberLimit - 1));
      setMinPageLimit(minPageLimit + (pageNumberLimit - 1));
    }

    setCurrentPage(prev => prev + 1);
  };

  const paginatedPosts = useMemo(() => {
    const posts = allPosts.slice(((currentPage - 1) * pageNumberLimit), ((currentPage - 1) * pageNumberLimit) + pageNumberLimit);
    return posts;
  }, [allPosts, currentPage, pageNumberLimit]);


  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    totalPages: Math.ceil((data.allContentfulCaseStudy.totalCount) / pageNumberLimit)
  };

  return (
    <Layout bannerData={bannerData}>
      {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          description={seoMetadata.description}
        />
      )}
      {sections && sections.length > 0 && <Box mb={[16, 32]}>
        <HeroSection sectionContent={sections[0]} />
      </Box>}
      <Box sx={{ position: 'relative', pt: [4], pb: [4], backgroundColor: "#F4F4F7"  }}>
        <Container>
          <Box sx={{
            display: 'flex',
            rowGap: "30px",
            listStyle: 'none',
            flexWrap: 'wrap',
            justifyContent: ['center', null, 'space-between'],
            alignItems: 'center',
            padding: 0,
            my: [2],
            '&::after': {
              content: '""',
              flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%']
            }
          }}>
            {paginatedPosts.map(({ node: post }) => (
              <Box
                key={post.id}
                sx={{
                  flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%'],
                  display: 'flex',
                  margin: '6px 0',
                }}
              >
                <CardWrapper
                  item={{
                    heading: post?.frontmatter?.title || post?.title,
                    image: post?.frontmatter?.featuredImage?.childImageSharp || post?.featuredImage,
                    imageAltText: post?.frontmatter?.title || post?.featuredImageAltText,
                    linkUrl: `/case-studies/${post.slug}`
                  }}
                />

              </Box>
            ))}
          </Box>
          <Pagination
            {...paginationAttributes}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onPageChange={onPageChange} />
        </Container>
      </Box>
      {sections && sections.length > 1 && <HeroSection sectionContent={sections[1]} />}
    </Layout>
  );
};


export default CaseStudiesIndex;
