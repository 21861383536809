/** @jsx jsx */
import { jsx, Box, Flex, Heading, Paragraph } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "./Link";
import Icon from "./Icon";



function wrapCharacterWithSpan(inputString, targetCharacter, fontWeight) {
  if (!inputString.includes(targetCharacter)) {
    return inputString;
  }

  const outputElements = [];

  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] === targetCharacter) {
      outputElements.push(<span key={i} sx={{fontFamily: 'system-ui', fontWeight}}>{targetCharacter}</span>);
    } else {
      outputElements.push(inputString[i]);
    }
  }

  return outputElements;
}

const CardWrapper = ({ item, cardHeight, type, ...props }) => {

  const { heading, image, linkUrl, imageAltText } = item;
  return (
    <Box {...props} sx={{
      borderBottom: '1px solid #40404191',
      width: '100%',
      height: {cardHeight},
    }}>
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
        }}>
        <Link sx={{ color: "inherit" }} to={linkUrl}>
          <Box
            className="text-center"
            sx={{
              overflowY: 'hidden',
              height: '248px',
            }}>
            {image && (
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.description || imageAltText}
                sx={{ height: '100%' }}
                imgStyle={{
                  objectFit: 'contain'
                }}
              />
            )}
          </Box>
          {type && <Flex
            sx={{
              flexDirection: 'column',
              p: [3, '0px 20px'],
              flex: '1 0 auto',
            }}>
            <Heading
              as="h6"
              variant="lessBold"
              sx={{ fontSize: "14px", color: "#223F99" }}
              mb={[2, 0]}>
              {type}
            </Heading>
          </Flex>}
          <Flex
            sx={{
              flexDirection: 'column',
              p: [3, '20px'],
              flex: '1 0 auto',
              height: '150px',
            }}>
            {heading && (
              <Heading
                as="h6"
                variant="lessBold"
                mb={[2, 0]}
              >
                {wrapCharacterWithSpan(heading.substring(0, 100) + (heading.length > 100 ? '...' : ''), 'ć', '550')}
              </Heading>
            )}
          </Flex>
          {item.author && <Flex
            sx={{
              flexDirection: 'column',
              p: [3, '0px 20px'],
              flex: '1 0 auto',
            }}>
            <Heading
              as="h6"
              variant="lessBold"
              sx={{ fontSize: "14px" }}
              mb={[2, 3]}>
              {item.author}
            </Heading>
          </Flex>}
          {item.date && <Flex
            sx={{
              flexDirection: 'column',
              p: [3, '0px 20px'],
              flex: '1 0 auto',
            }}>
            <Heading
              as="h6"
              variant="lessBold"
              sx={{ fontSize: "14px" }}
              mb={[2, 3]}>
              {item.date}
            </Heading>
          </Flex>}
          {item.description && <Flex
            sx={{
              flexDirection: 'column',
              p: [3, '0px 20px'],
              flex: '1 0 auto',
              height: '80px',
            }}>
            <Heading
              as="h6"
              variant="400"
              sx={{ fontSize: "14px" }}
              mb={[2, 2]}>
              {item.description.substring(0, 100)}...
            </Heading>
          </Flex>}
        </Link>
        <Flex sx={{ justifyContent: "flex-end", pb: 4, px: 4 }}>
          <Link sx={{ color: "#FF6310", fontSize: ["14px", "18px"], display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            to={linkUrl}>
            <Flex sx={{ alignItems: 'center' }}>
              <Paragraph sx={{ color: "#FF6310" }}> Read more </Paragraph>
              <Icon icon='bs-arrow-right' iconSize={18} />
            </Flex>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CardWrapper;